import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Layout from "./../layouts/Index";
import BlockLoader from "./../contentloaders/BlockLoader";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Container,
  Form,
  Button,
  Modal,
  FormControl,
} from "react-bootstrap";
import {
  getMoqs,
  getMoreMoqs,
  searchMoq,
  searchMoqByNumber,
  searchedMoqs,
} from "./../../store/actions/MoqActions";
import { getSuppliers } from "./../../store/actions/SupplierActions";
import { addOrder } from "./../../store/actions/OrderActions";
import { createSchema } from "./../../helpers/validation/order";
import { toast } from "react-toastify";
import { Formik } from "formik";
import moment from "moment";
import MoqAccordionItem from "./../common/MoqAccordionItem";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.css";
import "../../index.css";

class Index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,

      moq_id: null,

      loading: false,

      moq_number: "",

      product_name: "",

      searched: false,

      priviledge: 0,

      charLimit: 300,

      eta: null,
    };
  }

  componentDidMount = async () => {
    this.setState({
      loading: true,
      priviledge: parseInt(localStorage.getItem("ltdn_priv")),
    });

    const res = await this.props.getMoqs();

    if (res) {
      this.setState({ loading: false });
    }

    await this.props.getSuppliers();
  };

  toggleModal = (id = null) => {
    this.setState({ showModal: !this.state.showModal, moq_id: id });
  };

  createOrder = async (values) => {
    try {
      if (
        values.status === "Goods arrived at Litudian - Ready to collect" &&
        values.shipping_fee === ""
      ) {
        return toast.error("Shipping Fee Is Required !", {
          position: "top-right",
        });
      }

      if (
        values.status === "Shipper loaded - Goods in transit" &&
        this.state.eta === null
      ) {
        return toast.error("ETA field Is Required !", {
          position: "top-right",
        });
      }

      if (this.state.eta !== null) {
        values.eta = moment(this.state.eta).format("YYYY-MM-DD");
      }

      this.setState({ loading: true });

      const result = await this.props.addOrder(values);

      if (result) {
        toast.success("New Order Details Created Successfully !", {
          position: "top-right",
        });

        this.setState({ showModal: false, loading: false });

        return this.props.history.push("/admin/orders");
      }
    } catch (error) {
      console.log(error);

      this.setState({ loading: false });
    }
  };

  fetchMoreMoqs = async () => {
    try {
      this.setState({ loading: true });

      const {
        moqs: { next_page },
      } = this.props;

      const result = await this.props.getMoreMoqs(next_page);

      if (result) {
        this.setState({ loading: false });
      }
    } catch (error) {
      console.log(error);
    }
  };

  searchMoq = async () => {
    try {
      const { product_name, moq_number } = this.state;

      let obj = {};

      if (moq_number !== "") {
        obj.moq_number = moq_number;
      }

      if (product_name !== "") {
        obj.product_name = product_name;
      }

      this.setState({ loading: true });

      let result = null;

      if (obj.moq_number) {
        result = await this.props.searchMoqByNumber(obj);
      } else {
        result = await this.props.searchMoq(obj);
      }

      if (result) {
        await this.props.searchedMoqs(result);

        this.setState({ loading: false, searched: true });

        toast.success("MOQ Found !", {
          position: "top-right",
        });
      }
    } catch (error) {
      this.setState({ loading: false });

      if (error.response !== undefined && error.response.status === 404) {
        toast.warning("MOQ Not Found !", {
          position: "top-right",
        });
      }
    }
  };

  keyPress = async (e) => {
    try {
      if (e.keyCode === 13 && !this.state.loading) {
        await this.searchMoq();
      }
    } catch (error) {
      console.log(error);
    }
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  refresh = async () => {
    this.setState({ loading: true });

    const result = await this.props.getMoqs();

    if (result) {
      this.setState({
        loading: false,
        product_name: "",
        searched: false,
        moq_number: "",
      });
    }
  };

  render() {
    const {
      loading,
      moq_number,
      product_name,
      searched,
      priviledge,
      charLimit,
    } = this.state;

    const {
      moqs,
      history,
      suppliers,
      moqs: { page, pages },
    } = this.props;

    return (
      <Layout>
        <Col sm="12" style={{ minHeight: "80vh", padding: "1.5em 0" }}>
          <Container>
            <Row>
              <Col
                sm="12"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "row",
                  marginBottom: "1em",
                }}
              >
                <Form
                  onSubmit={async (e) => {
                    e.preventDefault();
                    if (this.state.loading) {
                      return;
                    }
                    await this.searchMoq();
                  }}
                >
                  <Row>
                    <Col>
                      <FormControl
                        type="text"
                        placeholder="Search Product ..."
                        name="product_name"
                        value={product_name}
                        className="mr-sm-2"
                        onKeyDown={this.keyPress}
                        onChange={this.handleChange}
                      />
                    </Col>
                    <Col>
                      <FormControl
                        type="text"
                        placeholder="Campaign Number"
                        name="moq_number"
                        value={moq_number}
                        // className="mr-sm-2"
                        onKeyDown={this.keyPress}
                        onChange={this.handleChange}
                      />
                    </Col>
                    <Col>
                      <Button variant="outline-success" type="submit">
                        Search
                      </Button>
                    </Col>

                    {searched === true && (
                      <Col>
                        <Button
                          variant="outline-success"
                          type="button"
                          onClick={() => {
                            this.refresh();
                          }}
                        >
                          Cancel
                        </Button>
                      </Col>
                    )}
                  </Row>
                </Form>
              </Col>

              <Col
                sm="12"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexDirection: "row",
                  marginBottom: "1em",
                }}
              >
                {!loading && <h3>Campaigns</h3>}

                {loading && (
                  <div style={{ width: "100px", height: "35px" }}>
                    <BlockLoader
                      width="100px"
                      height="35"
                      innerHeight="100%"
                      innerWidth="100%"
                      style={{ margin: "0", padding: "0" }}
                    />
                  </div>
                )}

                <Button
                  disabled={priviledge !== 2}
                  variant="success"
                  size="sm"
                  onClick={() => {
                    return history.push("/admin/moqs/create");
                  }}
                >
                  New Campaign
                </Button>
              </Col>

              {/* New Order Modal */}
              <Modal
                show={this.state.showModal}
                onHide={this.toggleModal}
                size="lg"
              >
                <Modal.Header closeButton>
                  <Modal.Title>New Order</Modal.Title>
                </Modal.Header>

                <Formik
                  enableReinitialize
                  initialValues={{
                    moq_id: this.state.moq_id !== null ? this.state.moq_id : "",
                    supplier_id: "",
                    status: "",
                    shipping_fee: "",
                    total_cost: 0,
                    shipping_company: "",
                    order_remarks: "",
                    shipping: "",
                  }}
                  validationSchema={createSchema}
                  onSubmit={async (values, { setSubmitting, resetForm }) => {
                    this.createOrder(values);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                  }) => (
                    <div>
                      <Modal.Body>
                        <Row>
                          <Form.Group
                            as={Col}
                            sm="6"
                            controlId="moq_id"
                            style={{ marginTop: "1em" }}
                          >
                            <Form.Label column sm="12">
                              Moq
                            </Form.Label>

                            <Col sm="12">
                              <Form.Control
                                as="select"
                                isValid={touched.moq_id && !errors.moq_id}
                                isInvalid={errors.moq_id && touched.moq_id}
                                name="moq_id"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                defaultValue={values.moq_id}
                              >
                                <option value="">Choose ...</option>

                                {moqs.data.map((moq, i) => (
                                  <option key={i} value={moq.id}>
                                    {moq.moq_number}
                                  </option>
                                ))}
                              </Form.Control>

                              {errors.moq_id && touched.moq_id && (
                                <Form.Control.Feedback type="invalid">
                                  {errors.moq_id}
                                </Form.Control.Feedback>
                              )}
                            </Col>
                          </Form.Group>

                          <Form.Group
                            as={Col}
                            sm="6"
                            controlId="supplier"
                            style={{ marginTop: "1em" }}
                          >
                            <Form.Label column sm="12">
                              Supplier
                            </Form.Label>

                            <Col sm="12">
                              <Form.Control
                                as="select"
                                isValid={
                                  touched.supplier_id && !errors.supplier_id
                                }
                                isInvalid={
                                  errors.supplier_id && touched.supplier_id
                                }
                                name="supplier_id"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                defaultValue={values.supplier_id}
                              >
                                <option value="">Choose ...</option>

                                {suppliers.map((supplier, i) => (
                                  <option key={i} value={supplier.id}>
                                    {supplier.name}
                                  </option>
                                ))}
                              </Form.Control>

                              {errors.supplier_id && touched.supplier_id && (
                                <Form.Control.Feedback type="invalid">
                                  {errors.supplier_id}
                                </Form.Control.Feedback>
                              )}
                            </Col>
                          </Form.Group>
                        </Row>
                        <Row>
                          <Form.Group
                            as={Col}
                            sm="6"
                            controlId="shipping_fee"
                            style={{ marginTop: "1em" }}
                          >
                            <Form.Label column sm="12">
                              Shipping Fee
                            </Form.Label>

                            <Col sm="12">
                              <Form.Control
                                type="number"
                                placeholder="Shipping Fee"
                                name="shipping_fee"
                                isValid={
                                  touched.shipping_fee && !errors.shipping_fee
                                }
                                isInvalid={
                                  errors.shipping_fee && touched.shipping_fee
                                }
                                value={values.shipping_fee}
                                onChange={handleChange}
                                disabled={
                                  values.status !==
                                  "Goods arrived at Litudian - Ready to collect"
                                }
                              />

                              {errors.shipping_fee && touched.shipping_fee && (
                                <Form.Control.Feedback type="invalid">
                                  {errors.shipping_fee}
                                </Form.Control.Feedback>
                              )}
                            </Col>
                          </Form.Group>

                          <Form.Group
                            as={Col}
                            sm="6"
                            controlId="total_cost"
                            style={{ marginTop: "1em" }}
                          >
                            <Form.Label column sm="12">
                              Total Cost
                            </Form.Label>

                            <Col sm="12">
                              <Form.Control
                                type="number"
                                placeholder="Total Cost"
                                name="total_cost"
                                isValid={
                                  touched.total_cost && !errors.total_cost
                                }
                                isInvalid={
                                  errors.total_cost && touched.total_cost
                                }
                                value={values.total_cost}
                                onChange={handleChange}
                                disabled={
                                  values.status !==
                                  "Supplier acknowledged - Preparing goods"
                                }
                              />

                              {errors.total_cost && touched.total_cost && (
                                <Form.Control.Feedback type="invalid">
                                  {errors.total_cost}
                                </Form.Control.Feedback>
                              )}
                            </Col>
                          </Form.Group>
                        </Row>
                        <Row>
                          <Form.Group
                            as={Col}
                            sm="6"
                            controlId="shipping_company"
                            style={{ marginTop: "1em" }}
                          >
                            <Form.Label column sm="12">
                              Shipping Company
                            </Form.Label>

                            <Col sm="12">
                              <Form.Control
                                type="text"
                                placeholder="Shipping Company"
                                name="shipping_company"
                                isValid={
                                  touched.shipping_company &&
                                  !errors.shipping_company
                                }
                                isInvalid={
                                  errors.shipping_company &&
                                  touched.shipping_company
                                }
                                value={values.shipping_company}
                                onChange={handleChange}
                              />

                              {errors.shipping_company &&
                                touched.shipping_company && (
                                  <Form.Control.Feedback type="invalid">
                                    {errors.shipping_company}
                                  </Form.Control.Feedback>
                                )}
                            </Col>
                          </Form.Group>

                          <Form.Group
                            as={Col}
                            sm="6"
                            controlId="status"
                            style={{ marginTop: "1em" }}
                          >
                            <Form.Label column sm="12">
                              Status
                            </Form.Label>

                            <Col sm="12">
                              <Form.Control
                                as="select"
                                isValid={touched.status && !errors.status}
                                defaultValue={values.status}
                                isInvalid={errors.status && touched.status}
                                name="status"
                                onChange={handleChange}
                                onBlur={handleBlur}
                              >
                                <option value="">Choose...</option>

                                <option value="Submitted - Pending supplier acknowledgement">
                                  Submitted - Pending supplier acknowledgement
                                </option>

                                <option value="Supplier acknowledged - Preparing goods">
                                  Supplier acknowledged - Preparing goods
                                </option>

                                <option value="At shipper - Loading goods">
                                  At shipper - Loading goods
                                </option>

                                <option value="Shipper loaded - Goods in transit">
                                  Shipper loaded - Goods in transit
                                </option>

                                <option value="Arrived - Customs clearance">
                                  Arrived - Customs clearance
                                </option>

                                <option value="Arrived - At shipper warehouse">
                                  Arrived - At shipper warehouse
                                </option>

                                <option value="Goods arrived at Litudian - Ready to collect">
                                  Goods arrived at Litudian - Ready to collect
                                </option>

                                <option value="Order cancelled">
                                  Order cancelled
                                </option>
                              </Form.Control>

                              {errors.status && touched.status && (
                                <Form.Control.Feedback type="invalid">
                                  {errors.status}
                                </Form.Control.Feedback>
                              )}
                            </Col>
                          </Form.Group>
                        </Row>
                        <Form.Group
                          as={Row}
                          controlId="order_remarks"
                          style={{ marginTop: "1em" }}
                        >
                          <Form.Label column sm="12">
                            Remarks
                          </Form.Label>

                          <Col sm="12">
                            <FormControl
                              as="textarea"
                              aria-label="With textarea"
                              name="order_remarks"
                              placeholder="write ..."
                              isValid={
                                touched.order_remarks && !errors.order_remarks
                              }
                              isInvalid={
                                errors.order_remarks && touched.order_remarks
                              }
                              value={values.order_remarks}
                              onChange={(e) => {
                                let txt = e.target.value;

                                if (txt.length <= charLimit) {
                                  setFieldValue("order_remarks", txt);
                                }
                              }}
                            />

                            {errors.order_remarks && touched.order_remarks && (
                              <Form.Control.Feedback type="invalid">
                                {errors.order_remarks}
                              </Form.Control.Feedback>
                            )}

                            <span
                              style={{ paddingTop: "5px", fontSize: "12px" }}
                            >
                              {charLimit - values.order_remarks.length}{" "}
                              characters remaining.
                            </span>
                          </Col>
                        </Form.Group>
                        <Row>
                          <Form.Group
                            as={Col}
                            sm="6"
                            controlId="eta"
                            style={{ marginTop: "1em" }}
                          >
                            <Form.Label column sm="12">
                              ETA
                            </Form.Label>
                            <Col sm="12">
                              <DatePicker
                                value={this.state.eta}
                                className="form-control"
                                showTimeSelect
                                timeFormat="HH:mm"
                                style={{ width: "100%" }}
                                selected={this.state.eta}
                                onChange={(date) =>
                                  this.setState({ eta: date })
                                }
                                dateFormat="MMMM d, yyyy h:mm aa"
                              />
                            </Col>
                          </Form.Group>
                          <Form.Group
                            as={Col}
                            sm="6"
                            controlId="supplier"
                            style={{ marginTop: "1em" }}
                          >
                            <Form.Label column sm="12">
                              Mode of Shipping
                            </Form.Label>

                            <Col sm="12">
                              <Form.Control
                                as="select"
                                isValid={touched.shipping && !errors.shipping}
                                isInvalid={errors.shipping && touched.shipping}
                                name="shipping"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                defaultValue={values.shipping}
                              >
                                <option value="">Choose ...</option>
                                <option value="air">Air</option>
                                <option value="sea">Sea</option>
                              </Form.Control>

                              {errors.shipping && touched.shipping && (
                                <Form.Control.Feedback type="invalid">
                                  {errors.shipping}
                                </Form.Control.Feedback>
                              )}
                            </Col>
                          </Form.Group>
                        </Row>
                      </Modal.Body>

                      <Modal.Footer>
                        <Button variant="secondary" onClick={this.toggleModal}>
                          Close
                        </Button>

                        <Button
                          variant="primary"
                          onClick={handleSubmit}
                          disabled={loading}
                        >
                          Create Order
                        </Button>
                      </Modal.Footer>
                    </div>
                  )}
                </Formik>
              </Modal>
            </Row>
          </Container>

          <Container>
            <Col sm="12" style={{ margin: 0, padding: 0 }}>
              {!loading &&
                moqs.data.map((moq, i) => (
                  <MoqAccordionItem
                    key={i}
                    moq={moq}
                    toggleModal={this.toggleModal}
                    priviledge={priviledge}
                  />
                ))}
            </Col>

            <Col sm="12" style={{ margin: 0, padding: 0 }}>
              {loading && (
                <BlockLoader
                  width="100%"
                  height="400px"
                  innerWidth="100%"
                  innerHeight="100%"
                />
              )}
            </Col>
          </Container>

          {page < pages && (
            <Col
              sm="12"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "2em",
              }}
            >
              <Button
                variant="primary"
                size="lg"
                onClick={() => this.fetchMoreMoqs()}
                disabled={loading}
              >
                {loading === true ? "Loading ..." : "Load More"}
              </Button>
            </Col>
          )}
        </Col>
      </Layout>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    moqs: state.moqs,

    suppliers: state.suppliers,
  };
};

export default connect(mapStateToProps, {
  getMoqs,
  addOrder,
  getSuppliers,
  getMoreMoqs,
  searchMoq,
  searchedMoqs,
  searchMoqByNumber,
})(withRouter(Index));
